@import '../../../../styles/utils';

.button {
  &.has-error {
    background-color: $danger-color !important;
    color: #fff !important;
  }
}

.button-loading-icon {
  padding: 2px;
  width: 1.6rem !important;
  height: 1.6rem !important;
  color: #fff !important;
}
