@import '../../../../../styles/utils';

.bar-chart {
  .chart {
    position: relative;
    width: 100%;
    height: 350px;
    margin: auto;

    g {
      line {
        stroke-opacity: 0.5;

        &[x1='0'] {
          &[y1='0'] {
            stroke-opacity: 0;

            &[y2='290'],
            &[y2='190'],
            &[y2='150'] {
              stroke-opacity: 0.1;
            }
          }
        }
      }
    }

    svg {
      text {
        opacity: 0.5;
      }
    }

    &.reduce-labels {
      svg {
        g {
          g {
            &:nth-child(2) {
              g {
                &:nth-child(1) {
                  g {
                    g {
                      &:not(:nth-child(4n + 1)) {
                        display: none;
                      }

                      &:first-child,
                      &:last-child {
                        display: block;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
