@import './utils';
@import '~hamburgers/_sass/hamburgers/hamburgers';

* {
  -webkit-tap-highlight-color: transparent;
}

::selection {
  background: $primary-color;
  color: #fff;
}

#app {
  height: 100%;
}

a {
  text-decoration: none;
  color: $primary-color;
  font-weight: 600;
}

img {
  width: 100%;
}

.no-wrap {
  white-space: nowrap;
}

.MuiDialogActions-root {
  padding: 20px !important;
}
