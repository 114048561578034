.view-card {
  cursor: pointer;

  position: relative;
  height: 140px;
  padding: 8px;

  .view-name {
    margin-bottom: 20px;
  }
}
