@import '../../../../styles/utils';

.page-views {
  .views {
    padding-top: 30px;
    padding-bottom: 110px;

    @include respond-from('medium') {
      padding-top: 50px;
    }

    .view {
      position: relative;
      height: 120px;

      .icons {
        position: absolute;
        right: 12px;
        bottom: 10px;

        .icon {
          width: 26px;
        }
      }
    }
  }
}
