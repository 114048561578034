@import '../../../../styles/utils';

.ui-hidden-text {
  @include select(none);

  .hidden-text-inner {
    position: relative;
    display: flex;
    align-items: center;
  }

  .hidden-text {
    position: absolute;
    top: 0;
    left: 0;
    letter-spacing: -0.03rem;
  }

  .text {
    color: #fff;
  }

  .eye {
    padding: 1px 5px;
    cursor: pointer;
    margin-left: 10px;
  }

  &.visible {
    .hidden-text {
      display: none;
    }

    .text {
      color: #000;
    }
  }
}
