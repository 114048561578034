@import '../../../../styles/utils';

.page-layout-basic {
  padding-top: 60px;

  .page-header {
    .page-title-outer {
      display: flex;
      align-items: center;
      margin-top: 30px;

      .back {
        margin-top: 9px;
        margin-left: -10px;
        cursor: pointer;
        padding: 0 10px;
      }

      .options {
        margin-left: auto;
      }
    }
  }

  .page-inner {
    width: calc(100% - 40px);
    max-width: 1000px;
    margin: auto;

    @include respond-from('medium') {
      width: calc(100% - 200px);
    }
  }

  &.center-title {
    .page-title {
      margin: auto;
    }
  }

  &.title-in-header {
    .page-title-outer {
      @include respond-from('medium') {
        position: fixed;
        top: 0;
        left: 50%;
        text-align: center;
        z-index: 150;
        margin-top: 12px;
        transform: scale(0.9) translateX(-50%);
      }
    }
  }

  &.full-width {
    .page-inner {
      width: 100%;
      max-width: 100%;
    }
  }
}
