@import '../../../../styles/utils';

.logo {
  position: fixed;
  top: 10px;
  left: calc(50% - 19px);
  width: 44px;
  transform: translateX(0);
  padding: 10px;
  z-index: 200;

  @include select(none);
  @include respond-from('medium') {
    top: 10px;
    left: 30px;
  }
}
