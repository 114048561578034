@import '../../../styles/utils';

.banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  border-top: 1px solid $line-color;
  background-color: #fff;

  .banner-inner {
    display: flex;
    align-items: center;
    width: calc(100% - 40px);
    height: 100%;
    max-width: 1000px;
    margin: auto;

    .banner-message {
      font-size: 0.75rem;

      @include respond-from('medium') {
        font-size: 1rem;
      }
    }

    .signup {
      margin-left: auto;

      .signup-btn {
        width: 150px;
      }
    }
  }
}
