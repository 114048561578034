@import '../../../styles/utils';

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: #fff;
  z-index: 100;
  border-bottom: 1px solid $line-color;

  .menu-link {
    position: fixed;
    top: 5px;
    right: 20px;
    z-index: 300;

    @include respond-from('medium') {
      top: 3px;
      right: 15px;
    }

    @include respond-from('extra-large') {
      right: 30px;
    }
  }

  .demo-tag {
    position: fixed;
    top: 15px;
    right: 20px;
    background-color: $default-color;
    color: #fff;
    border-radius: 4px;
    padding: 5px 10px;
    padding-bottom: 6px;
    text-transform: uppercase;
    font-size: 0.7rem;
    font-weight: 600;
  }
}
