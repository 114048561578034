@import '../../../../../styles/utils';

.twitter-mentions {
  .twitter-mention {
    cursor: pointer;

    .MuiListItemText-primary {
      color: $title-color;
    }
  }
}
