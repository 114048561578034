@import '../../../../styles/utils';

.page-view {
  padding-bottom: 90px;

  .views-navigation {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    display: flex;
    border-top: 1px solid $line-color;
    background-color: #fff;
  }
}
