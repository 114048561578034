@import '../../../../styles/utils';

.result {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;

  .result-inner {
    width: calc(100% - 40px);
    max-width: 600px;
    text-align: center;
  }

  .content {
    padding: 50px !important;
  }

  .actions {
    margin-top: 30px;
  }

  .icon {
    margin-bottom: 30px;

    svg {
      color: #f50;
      font-size: 3rem;
    }
  }
}
