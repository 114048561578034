@import '../../../styles/utils';

.view-card-details {
  position: fixed;
  top: 60px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;

  .background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.9);
  }

  .view-card-details-card {
    position: fixed;
    top: 0;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: calc(100% - 40px);
    max-width: 800px;
    max-height: calc(100% - 40px);
    overflow: auto;
  }

  .view-card-details-heading {
    padding: 10px;

    .interval-select {
      width: 100%;
    }

    @include respond-from('medium') {
      display: flex;
      align-items: center;
      padding: 20px 10px;

      .interval-select {
        width: 150px;
        margin-left: auto;
      }
    }
  }

  .MuiCard-root {
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.075);
  }
}
