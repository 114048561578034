@import '../../../../styles/utils';

.ui-no-items {
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  text-align: center;
  color: $default-color;
  height: 200px;

  @include respond-from('medium') {
    height: 300px;
  }

  .ant-typography {
    color: $default-color;
  }

  span {
    font-size: 3rem;
    opacity: 0.8;
    transform: scaleX(1.2);
    margin-top: 10px;
  }
}
