$global-speed: 1s;
$small-max-width: 699px;
$medium-min-width: 700px;
$medium-max-width: 949px;
$large-min-width: 950px;
$large-max-width: 1499px;
$extra-large-min-width: 1500px;
$ease: cubic-bezier(0.34, 0, 0.58, 1);
$border-radius: 8px;

// colors
$primary-color: #2165f5;
$title-color: #000000;
$line-color: #f0f0f0;
$line-dark-color: #e5e5e5;
$default-color: #838896;
$success-color: #0ccb89;
$enabled-color: #346dff;
$warning-color: #ff9f46;
$danger-color: #d7153f;

// window sizes
$menu-small-max-width: 699px;
$menu-medium-min-width: 700px;
$menu-medium-max-width: 949px;
$menu-large-min-width: 950px;
$menu-large-max-width: 1499px;
$menu-extra-large-min-width: 1500px;

@mixin select($args) {
  -webkit-user-select: $args;
  -moz-user-select: $args;
  -ms-user-select: $args;
  user-select: $args;
}

@mixin transition($prop, $speed, $ease) {
  transition: $prop ($speed * $global-speed) $ease;
}

@mixin respond-from($type) {
  @if $type == medium {
    @media (min-width: $medium-min-width) {
      @content;
    }
  } @else if $type == large {
    @media (min-width: $large-min-width) {
      @content;
    }
  } @else if $type == 'extra-large' {
    @media (min-width: $extra-large-min-width) {
      @content;
    }
  }
}

@mixin respond-up-to($type) {
  @if $type == medium {
    @media (max-width: $small-max-width) {
      @content;
    }
  } @else if $type == large {
    @media (max-width: $medium-max-width) {
      @content;
    }
  } @else if $type == 'extra-large' {
    @media (max-width: $large-max-width) {
      @content;
    }
  }
}
