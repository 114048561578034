@import '../../../../styles/utils';

.ui-status-indicator {
  padding: 10px;

  .ui-status-color {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: $default-color;
    transition: background-color 0.5s $ease, box-shadow 0.2s $ease;
  }

  &:hover {
    .ui-status-color {
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    }
  }

  &.status-active {
    .ui-status-color {
      background-color: $success-color;
    }
  }

  &.status-published {
    .ui-status-color {
      background-color: $success-color;
    }
  }

  &.status-draft {
    .ui-status-color {
      background-color: $default-color;
    }
  }

  &.status-inactive {
    .ui-status-color {
      background-color: $default-color;
    }
  }

  &.status-unsaved {
    .ui-status-color {
      background-color: $warning-color;
    }
  }
}
