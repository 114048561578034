@import '../../../../styles/utils';

.ui-hidden-editable-text {
  position: relative;
  height: 25px;
  @include select(none);

  .hidden-text-inner {
    position: relative;
    display: flex;
    align-items: center;
  }

  .hidden-text {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 30px);
    letter-spacing: 0.04rem;
    overflow: hidden;
    white-space: nowrap;
    z-index: 1;
  }

  .text {
    color: #fff;
  }

  .eye {
    padding: 1px 5px;
    cursor: pointer;
    margin-left: 10px;
  }

  .ant-typography {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    color: transparent;
    z-index: 2;

    &.ant-typography-edit-content {
      color: #000;
    }
  }

  .ant-typography-edit {
    position: absolute;
    right: 0;
    top: 0;
  }
}
