@import '../../../styles/utils';

.view-grid {
  padding-top: 30px;
  padding-bottom: 30px;

  @include respond-from('medium') {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .preparing {
    max-width: 400px;
    margin: auto;
    text-align: center;
    padding: 20px;

    .title {
      margin-bottom: 10px;
    }

    @include respond-from('medium') {
      margin-top: 100px;
    }
  }

  .view-groups {
    .view-group {
      &:not(:first-child) {
        margin-top: 50px;
      }

      .view-group-name {
        font-size: 1rem;
        margin-bottom: 20px;
      }
    }
  }
}

.view-grid-modal {
  .MuiBackdrop-root {
    background-color: rgba(255, 255, 255, 0.5) !important;
  }
}
